.rounded-image {
  padding: 0.25rem;
  object-fit: contain;

  .img-thumbnail {
    &.img-error {
      object-fit: cover;
    }
  }
}
