.verified {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $body-bg;
  margin-bottom: -8px;

  img {
    filter: drop-shadow(0px 0px 0px rgba(10, 10, 10, 0.05))
      drop-shadow(0px 4.667px 4.667px rgba(10, 10, 10, 0.05))
      drop-shadow(5.833px 9.333px 9.333px rgba(10, 10, 10, 0.05));
  }
}

.connect-strava {
  gap: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  .apps-wrapper {
    width: 72px;
    height: 72px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-width: 8px;
    border-style: solid;

    img {
      width: 64px;
      height: 64px;
      padding: 8px;
    }
  }

  .chain-wrapper {
    display: flex;
    position: absolute;
    padding: 1px 6px;
    align-items: center;
    border-radius: 16px;

    &.success {
      border: 1px solid $ocean-green;
      background: linear-gradient(0deg, #eafcf8 0%, #eafcf8 100%), #fff;
    }

    &.failed {
      border: 1px solid $red;
      background: linear-gradient(0deg, rgba(255, 212, 212, 0.4) 0%, rgba(255, 212, 212, 0.4) 100%),
        #fff;
      transform: rotate(-15deg);

      i {
        transform: rotate(15deg);
      }
    }

    /* shadow-borders/sm */
    box-shadow: 0px 0px 0px 1px rgba(10, 10, 10, 0.05), 0px 4px 4px -2px rgba(10, 10, 10, 0.05),
      5px 8px 8px -4px rgba(10, 10, 10, 0.05);
  }
}

.invite-group-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  width: 80%; /* Adjust width for mobile responsiveness */
  max-width: 400px; /* Max width to maintain proportions */
  padding: 20px;
  box-sizing: border-box;
}
.invite-group-logo-container {
  background-color: #2ad8b7;
  padding: 15px;
  border-radius: 20px;
  width: 60%; /* Proportionate to the overlay width */
  max-width: 200px; /* Maximum size */
  margin: 0 auto 30px; /* Center and add space below */
}
.invite-group-logo {
  background: $logo-light no-repeat center center;
  background-size: contain;
  width: 137px;
  height: 26px;
  margin: 0 auto; /* Center the logo */
}
.invite-group-content {
  font-size: 1em; /* Adjust font size as needed */
  margin-bottom: 30px; /* Space above the buttons */
}
.invite-group-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block; /* Align buttons side by side */
  margin: 10px 5px; /* Space between buttons */
}
.invite-group-btn img {
  height: auto; /* Maintain aspect ratio */
  width: 126px; /* Button width as provided */
}

.logo-factum-background {
  background-image: url('../images/logo-light.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 24px;
}
