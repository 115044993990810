.country-code-select {
  width: fit-content;
  min-width: 85px;

  > div {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .react-select__menu {
    width: 300%;
  }
}

@include media-breakpoint-down(md) {
  .country-code-select {
    .react-select__menu {
      max-width: 225px;
    }
  }
}
