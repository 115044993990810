.toast-container {
  top: $topbar-height !important;
  z-index: 1002;
}

.toast {
  border-radius: 5px !important;
}

.toast-body {
  background-color: $toast-background-color;
  border-radius: 4px !important;
  padding-top: 0.5rem;
}

.authentication-bg {
  .toast-container {
    top: 0px !important;
  }
}

@each $color, $value in $theme-colors {
  .toast {
    &.bg-#{$color} {
      color: shade-color($value, 30%);
      background-color: $value !important;
      border-color: rgba($value, 0.25);

      .icon {
        color: $value;
      }

      strong {
        font-size: 1rem !important;
      }
    }
  }
}
