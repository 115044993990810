.chart-card {
  .accordion {
    height: 100%;
    border: $accordion-border-color $accordion-border-width solid;

    @include border-bottom-radius($accordion-border-radius);
    @include border-top-radius($accordion-border-radius);

    .accordion-item {
      border-left: 0;
      border-right: 0;

      &:first-child {
        border-top: 0;
      }

      &:last-child {
        @include border-bottom-radius(0);
      }

      .accordion-header {
        .accordion-button {
          background-color: transparent;
          box-shadow: none;
          color: $raisin-black;
          font-weight: 600;
          padding: 24px;

          &::after {
            content: '\e903';
            font-family: 'icomoon';
            background-image: none;
            font-size: 1.5rem;
            font-weight: 500;
          }
        }
      }
    }
  }
}
