.detail-card-header {
  border-bottom: 1px solid $gray-200;
  padding-bottom: 0.7rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  .widget-icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 24px;
  }
}
