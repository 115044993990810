@each $color, $value in $theme-colors {
  .tab.tab-outline-#{$color} {
    border: 1px solid $value;
    color: $gray-700 !important;
    background-color: transparent !important;
  }
}

.tab-wrapper {
  flex-wrap: nowrap;

  * {
    font-size: 0.9rem;
  }

  .nav-item {
    &:not(:last-child),
    &:nth-last-child(n + 3) {
      .nav-link {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    &:not(:first-child) {
      .nav-link {
        border-left: none !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    .nav-link {
      font-weight: 500;
      padding: 0.25rem 0.75rem;

      &:hover {
        color: $raisin-black !important;
      }

      &.active {
        background-color: $body-bg !important;
        color: $raisin-black !important;

        .indicator {
          background-color: $white;
        }
      }

      .indicator {
        padding: 0 0.2rem;
        background-color: $body-bg;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .tab-wrapper {
    width: 100%;

    .nav-item {
      width: 100%;
    }
  }
}
