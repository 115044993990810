.swiper {
  .swiper-button-prev {
    width: 2.25rem !important;
    height: 2.25rem !important;
    border-radius: 6px !important;
    background-color: rgba($black, 0.5) !important;
    opacity: 1 !important;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    pointer-events: visible;

    &.swiper-button-disabled::after {
      color: $secondary;
    }

    &::after {
      color: $white;
      font-size: 20px;
      content: '\e902';
      font-family: 'icomoon' !important;
    }
  }

  .swiper-button-next {
    width: 2.25rem !important;
    height: 2.25rem !important;
    border-radius: 6px !important;
    background-color: rgba($black, 0.5) !important;
    opacity: 1 !important;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    pointer-events: visible;

    &.swiper-button-disabled::after {
      color: $secondary;
    }

    &::after {
      font-size: 20px;
      color: $white;
      content: '\e949';
      font-family: 'icomoon' !important;
    }
  }

  .swiper-slide-active {
    pointer-events: fill;
  }
}

.swiper-pagination {
  pointer-events: visible;
  margin: auto !important;
  background-color: rgba($black, 0.6) !important;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border-radius: $border-radius-pill;
  opacity: 1 !important;
  padding: 5px;
  overflow: hidden;

  &:not(.swiper-pagination-bullets-dynamic) {
    height: max-content;
    width: -moz-fit-content !important;
    width: fit-content !important;
    line-height: 0;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .swiper-pagination-bullet {
    background-color: $secondary;
    margin: 0 2.5px !important;
    opacity: 1;
    width: 5px;
    height: 5px;

    &.swiper-pagination-bullet-active {
      background-color: $white;
    }
  }
}
