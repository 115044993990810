//
// preloader.scss
//

.preloader {
  &.centered {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.cover-page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -$topbar-height;
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;

  > div {
    width: 15px;
    height: 15px;
    margin: 32px 4px;
    background: $primary;
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.2s;
      background: $danger;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      background: $success;
    }
  }

  &.size-md {
    > div {
      width: 15px;
      height: 15px;
      margin: 32px 4px;
    }
  }

  &.size-sm {
    > div {
      width: 12px;
      height: 12px;
      margin: 32px 4px;
    }
  }

  &.size-lg {
    > div {
      width: 25px;
      height: 25px;
      margin: 36px 8px;
    }
  }
}

.story-card.skeleton.card {
  .header-skeleton {
    cursor: wait;
    height: 150px;
    margin: -12px;
    width: auto !important;
    border-radius: 12px 12px 0 0;
    animation: 2s linear infinite placeholder-wave;
    -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    background-color: rgba($gray-700, 0.5);
  }
  div.card-body {
    border: none;

    div {
      width: 100%;
    }
  }
}
