//
// alert.scss
//

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    color: shade-color($value, 5%);
    background-color: rgba($value, 0.18);
    border-color: rgba($value, 0.25);

    .alert-link {
      color: shade-color($value, 30%);
    }
  }
}

.delete-story-item-alert {
  position: absolute !important;
  bottom: 0;
  left: 0;
  background-color: $danger-light !important;
  border-radius: 0px 0px 16px 16px;
  z-index: 2;
  padding: 25px;

  span.description {
    color: #d37e7e;
  }
}
