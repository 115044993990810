@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?y4yjuo');
  src: url('../fonts/icomoon.eot?y4yjuo#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?y4yjuo') format('truetype'),
    url('../fonts/icomoon.woff?y4yjuo') format('woff'),
    url('../fonts/icomoon.svg?y4yjuo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-TDU:before {
  content: '\e94c';
}
.icon-video:before {
  content: '\e94b';
}
.icon-arrow-r:before {
  content: '\e949';
}
.icon-arrow-l:before {
  content: '\e902';
}
.icon-user-check:before {
  content: '\e94a';
}
.icon-increase:before {
  content: '\e948';
}
.icon-decrease:before {
  content: '\e943';
}
.icon-user-add:before {
  content: '\e945';
}
.icon-chain-link:before {
  content: '\e942';
}
.icon-broken-chain-link:before {
  content: '\e941';
}
.icon-apps:before {
  content: '\e93f';
}
.icon-aws:before {
  content: '\e93e';
}
.icon-lock:before {
  content: '\e93d';
}
.icon-setting-2:before {
  content: '\e93c';
}
.icon-km:before {
  content: '\e940';
}
.icon-file-text:before {
  content: '\e939';
}
.icon-check-circle:before {
  content: '\e936';
}
.icon-close-circle:before {
  content: '\e937';
}
.icon-question-circle:before {
  content: '\e938';
}
.icon-copy:before {
  content: '\e92f';
}
.icon-crown:before {
  content: '\e930';
}
.icon-flag:before {
  content: '\e931';
}
.icon-location:before {
  content: '\e932';
}
.icon-user:before {
  content: '\e933';
}
.icon-user-remove:before {
  content: '\e934';
}
.icon-key:before {
  content: '\e92e';
}
.icon-chevron-l:before {
  content: '\e925';
}
.icon-chevron-d:before {
  content: '\e903';
}
.icon-notification:before {
  content: '\e900';
}
.icon-turn:before {
  content: '\e901';
}
.icon-block:before {
  content: '\e904';
}
.icon-menu:before {
  content: '\e905';
}
.icon-bookmark:before {
  content: '\e906';
}
.icon-analytic:before {
  content: '\e907';
}
.icon-calendar:before {
  content: '\e908';
}
.icon-filter:before {
  content: '\e909';
}
.icon-sort:before {
  content: '\e90a';
}
.icon-pin-location:before {
  content: '\e90b';
}
.icon-clock:before {
  content: '\e90c';
}
.icon-edit:before {
  content: '\e90d';
}
.icon-double-chevron-l:before {
  content: '\e90e';
}
.icon-help-support:before {
  content: '\e90f';
}
.icon-chevron-vertical:before {
  content: '\e910';
}
.icon-trash:before {
  content: '\e911';
}
.icon-circle-info:before {
  content: '\e912';
}
.icon-more:before {
  content: '\e913';
}
.icon-arrow-turn-l:before {
  content: '\e914';
}
.icon-message:before {
  content: '\e915';
}
.icon-paperclip:before {
  content: '\e916';
}
.icon-setting:before {
  content: '\e917';
}
.icon-scan:before {
  content: '\e918';
}
.icon-image:before {
  content: '\e919';
}
.icon-people:before {
  content: '\e91a';
}
.icon-close:before {
  content: '\e91b';
}
.icon-min:before {
  content: '\e91c';
}
.icon-email:before {
  content: '\e91d';
}
.icon-eye-close:before {
  content: '\e91e';
}
.icon-check:before {
  content: '\e91f';
}
.icon-plus:before {
  content: '\e920';
}
.icon-double-chevron-r:before {
  content: '\e921';
}
.icon-download:before {
  content: '\e922';
}
.icon-log-out:before {
  content: '\e923';
}
.icon-double-chevron-u:before {
  content: '\e924';
}
.icon-double-chevron-b:before {
  content: '\e92d';
}
.icon-chevron-r:before {
  content: '\e927';
}
.icon-person:before {
  content: '\e928';
}
.icon-chevron-u:before {
  content: '\e929';
}
.icon-eye-open:before {
  content: '\e92a';
}
.icon-search:before {
  content: '\e92b';
}
.icon-home-bold:before {
  content: '\e935';
}
.icon-flag-bold:before {
  content: '\e944';
}
.icon-elevation-bold:before {
  content: '\e946';
}
.icon-stopwatch-bold:before {
  content: '\e947';
}
.icon-flag1:before {
  content: '\e93a';
}
.icon-elevation:before {
  content: '\e926';
}
.icon-stopwatch:before {
  content: '\e93b';
}
.icon-home:before {
  content: '\e92c';
}

.icon-16px.icon-set,
.icon-16px.icon {
  font-size: 16px;
}

.icon-18px.icon-set,
.icon-18px.icon {
  font-size: 18px;
}

.icon-20px.icon-set,
.icon-20px.icon {
  font-size: 20px !important;
}

.icon-24px.icon-set,
.icon-24px.icon {
  font-size: 24px !important;
}

.icon-36px.icon-set,
.icon-36px.icon {
  font-size: 36px;
}

.icon-48px.icon-set,
.icon-48px.icon {
  font-size: 48px;
}
