@include media-breakpoint-down(sm) {
  .basic-table {
    thead {
      display: none;
    }

    td {
      display: flex;
      align-items: center;
      text-align: right;
      padding: 0.4rem 0.95rem;

      &:first-child {
        padding-top: 0.8rem;
      }

      &:last-child {
        padding-bottom: 0.55rem;
      }

      &::before {
        flex: 1;
        text-align: left;
        margin-bottom: unset;
      }

      > * {
        max-width: 70%;
      }

      .col-with-image {
        flex-direction: row-reverse;

        img {
          margin-right: 0;
          margin-left: 0.6rem;
        }
      }
    }
  }
}
