//
// reboot.scss
//

body {
  padding-right: 0px !important;
  padding-left: 0px !important;
  &.loading {
    visibility: hidden;
  }
}

button,
a {
  outline: none !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  color: $text-muted;
}

// Forms

label {
  font-weight: 400;
}

// Address
address.address-lg {
  line-height: 24px;
}

b,
strong {
  font-weight: $font-weight-bold;
}

.rounded-4 {
  border-radius: 0.5rem;
}

.rounded-inherit {
  border-radius: inherit !important;
}

body.invite-group {
  height: 100%;
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  text-align: center;
  color: #fff;
  background: $invite-group-bg no-repeat center center fixed;
  background-size: cover;
}
