.color-palette {
  border: 3px solid $white;
  box-shadow: 0 0 2px $gray-700;
  border-radius: 100%;
  height: 30px;
  width: 30px;
}

.qr-code-wrapper {
  display: flex;
  align-items: center;
  width: fit-content;

  .qr-code {
    width: 70px;
    height: 70px;
  }
}
