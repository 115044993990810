//
// forms.scss
//

// Form-control light
.form-control-light {
  background-color: #{map-get($grays, '100')} !important;
  border-color: #{map-get($grays, '100')} !important;
}

// Form elements (Color and Range)
input.form-control[type='color'],
input.form-control[type='range'] {
  min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
  &:focus {
    box-shadow: none !important;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: $input-height;
  }
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0 !important;
}

.input-group:not(.has-validation) {
  > :has(+ [data-lastpass-icon-root]) {
    border-top-right-radius: $input-border-radius !important;
    border-bottom-right-radius: $input-border-radius !important;
  }
}

.input-group {
  border-radius: $input-border-radius;

  .form-control:not(:first-child) {
    border-left: 0 !important;
  }

  &.group-float {
    box-sizing: border-box !important;
    border: 1px solid $border-color;

    input.form-control {
      padding-right: 0px !important;
      outline: none !important;
      border: 0 !important;

      &:first-child {
        border-right: 0 !important;
      }
    }

    &:focus-within {
      outline: 2px solid $primary-light;
      border: 1px solid $primary;
    }

    &.is-invalid {
      border: 1px solid $danger !important;
    }
  }

  &.is-invalid {
    outline: 2px solid $danger-light !important;

    .form-control,
    .react-select.is-invalid .react-select__control {
      outline: none !important;
    }

    .input-group-text:not(:last-child),
    .form-control:not(:last-child):not(.app-version, .build-version-input) {
      border-right-color: transparent !important;
    }

    .react-select.is-invalid:first-child .react-select__control {
      border-right: 1px solid $danger !important;
      margin-right: 1px;
    }

    .input-group-text:not(:first-child),
    .form-control:not(:first-child) {
      border-left-color: transparent !important;
    }

    .input-group-text {
      background-color: $danger;
      color: $white;
      border: 1px solid $danger !important;
    }
  }

  &:focus-within {
    outline: 2px solid $primary-light;

    .form-control,
    .react-select__control {
      outline: none !important;
      border: 1px solid $primary;
    }

    .react-select:first-child .react-select__control {
      border: 1px solid $primary;
    }

    .input-group-text:not(:first-child),
    .form-control:not(:first-child),
    .password-input:not(:first-child) {
      border-left: transparent !important;
    }

    .input-group-text {
      border: 1px solid $primary;
    }

    &.password-input {
      .form-control:not(:last-child) {
        border-right: transparent !important;
      }
    }
  }

  .build-version-input {
    padding-right: 80px !important;
    border-radius: 0 $input-border-radius $input-border-radius 0 !important;
    z-index: 3;
  }

  .input-group-text {
    padding: 0.15rem $input-group-addon-padding-x;
    line-height: 1;
  }

  .input-group-password {
    background-color: $tertiary;
  }

  span {
    font-size: 24px;
  }
}

select.form-control-sm {
  &:not([size]):not([multiple]) {
    height: $input-height-sm;
  }
}

// Show Password
.password-eye {
  &:before {
    font-family: 'Icomoon';
    content: '\e92a';
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    vertical-align: middle;
    line-height: 1.2;
    font-size: 22px;
  }
}

.show-password {
  .password-eye {
    &:before {
      content: '\e91e';
    }
  }
}

.form-control-color.is-invalid {
  width: 4.8rem;
}

// Dark mode
body[data-layout-color='dark'] {
  .form-control-light {
    background-color: #{map-get($dark-grays, '100')} !important;
    border-color: #{map-get($dark-grays, '100')} !important;
  }
}

// Form control border color
.was-validated .form-control:invalid,
.form-control.is-invalid {
  outline: 2px solid $danger-light !important;
  border: 1px solid $danger !important;
}

.form-control:focus {
  outline: 2px solid $primary-light;
}

// Custom OTP input
.otp-input-container {
  gap: 8px;
  justify-content: center;

  .otp-input {
    width: calc(100% / 6) !important;
    height: calc(100% / 6) !important;
    max-width: 52px;
    max-height: 52px;
    padding: 0 !important;
    aspect-ratio: 1/1;
    border-radius: 8px;

    &.is-invalid {
      background-image: none;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.resend-otp {
  max-width: 95%;
}

@include media-breakpoint-up(sm) {
  .otp-input-container {
    gap: 16px;
  }
}

.verify-otp {
  max-width: 345px;
  width: 90% !important;
}

input.form-control {
  padding: 10px 12px !important;
}

.invalid-feedback {
  font-size: 0.85rem;
}

.selected-items {
  appearance: none;
  height: 1.5rem;
  width: 1.5rem;
  background-color: $white;
  border: 1px solid $gray-light;
  border-radius: 50%;
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    transition: 100ms transform ease-in-out;
    box-shadow: inset 1em 1em $primary;
    transform: scale(0);
  }

  &:checked {
    border-color: $primary;

    &::before {
      transform: scale(1);
    }
  }
}

.password-validation {
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: 0.375rem;
  background-color: $white;
  z-index: 10;

  .icon-info-wrapper {
    padding: 1px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $danger;

    &.is-valid {
      background-color: $primary;
    }
  }
}

.form-switch .form-check-input {
  width: 2.5rem !important;
  height: 1.5rem !important;
  cursor: pointer;
}

.feature-flag-label {
  margin-left: 2.75rem;
  margin-top: -0.5rem;
  display: block !important;
}

.supported-version-button {
  gap: 0.5rem;
  display: flex;
  align-items: center !important;
  position: absolute !important;
  right: 0.5rem;
  border-radius: 4px !important;
  padding: 4px 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3 !important;
}

.date-filter-analytic {
  border-radius: 0.4rem;
  width: 126px;
  height: 2rem;

  .form-control {
    padding: 0 0.5rem !important;
    height: 100%;

    &::placeholder,
    &::-ms-input-placeholder {
      color: $raisin-black;
      font-weight: 500;
    }
  }

  .react-datepicker__input-container {
    height: 100%;
  }
}

.select-filter-analytic {
  border-radius: 0.4rem;
  height: 2rem;

  .react-select__value-container .react-select__single-value {
    color: $raisin-black !important;
    font-weight: 500;
  }
}
