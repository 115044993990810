.share-event-container {
  width: 1200;
  padding: 40px;
  aspect-ratio: 1.91 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: auto;
  background-image: url('../../../assets/images/event-background.png');
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.card-container {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  aspect-ratio: 1.91 / 1;
  overflow: hidden;
  position: relative;

  .route-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: -50px;
  }

  .event-details {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;

    .logo-image {
      width: 36px;
    }

    .event-details-text {
      max-width: 70%;
      word-break: keep-all;
      font-size: 36px;
      font-weight: 600;
      line-height: 50px;
      letter-spacing: -0.8px;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #222222;
    }
  }
}

.card-container-multiple {
  display: grid;
  grid-template-columns: 1fr 20%;
  height: 100%;
  width: 100%;
  gap: 40px;
  position: relative;

  .button-carousel {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3.5%;
    width: 144px;
    height: 144px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 10%), 0px 1px 4px 0px rgba(0, 0, 0, 25%);
  }

  .route-items {
    width: 986px;
    border-radius: 24px;
    position: relative;
    overflow: hidden;

    &:nth-child(2) {
      justify-content: center;
      align-items: center;
      display: flex;

      .route-img {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        width: 110%;
        height: 110%;
        margin-top: 0px;
      }
    }

    .route-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: -50px;
    }

    .event-details {
      height: 100px;
      width: 1016px;
      display: flex;
      flex-direction: row;
      gap: 16px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 60px 60px 0px 0px;
      justify-content: center;
      align-items: center;

      .logo-image {
        width: 36px;
      }

      .event-details-text {
        max-width: 70%;
        word-break: keep-all;
        font-size: 36px;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: -0.8px;
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #222222;
      }
    }
  }
}
