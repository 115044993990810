.strava-icon-wrapper {
  background-color: #fc4c02;
  display: flex;
  padding: 4px;
  gap: 4px;
  justify-content: center;
  align-items: center;

  &.circle {
    border-radius: 100%;
    height: 26px;
    width: 26px;
  }

  &.square {
    border-radius: 4px;
    height: 26px;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.garmin-icon-wrapper {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;

  &.circle {
    background-color: #e6f6fd;
    border-radius: 100%;
    height: 26px;
    width: 26px;

    img {
      height: 18px;
      width: 18px;
    }
  }

  &.square {
    background-color: #6ecff6;
    border-radius: 4px;
    height: 26px;
    width: -moz-fit-content;
    width: fit-content;

    img {
      height: 18px;
      filter: brightness(0) invert(1);

      &.logo-text {
        height: 20px;
      }
    }
  }
}

.wahoo-icon-wrapper {
  background-color: $black;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;

  &.circle {
    border-radius: 100%;
    height: 26px;
    width: 26px;

    img {
      filter: brightness(0) invert(1);
    }
  }

  &.square {
    border-radius: 4px;
    height: 26px;
    width: -moz-fit-content;
    width: fit-content;

    img {
      height: 12px;
      filter: brightness(0) invert(1);
    }
  }
}
