//
// icons.scss
//

@import 'icons/materialdesignicons';

.mdi-25px.mdi-set,
.mdi-25px.mdi:before {
  font-size: 25px;
}
