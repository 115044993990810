table.advanced-table {
  border-collapse: separate;
  border-spacing: 0;
  border-style: hidden;

  th,
  td {
    .row-selection {
      input {
        margin: 0;
        border-radius: 8px;
        padding: 10px;
      }
    }

    .table-action-dropdown {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray-light;
      background-color: transparent;
      padding: 6px;
      border-radius: 4px;
    }
  }

  th {
    border: 0;
    background-color: $body-bg !important;
    color: $secondary;
    position: relative;

    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
    }

    .min-width-100 {
      min-width: 100px;
    }

    .min-width-150 {
      min-width: 150px;
    }

    .min-width-200 {
      min-width: 200px;
    }
  }

  tbody {
    &.error {
      td {
        border: 1px solid $gray-light;
        border-end-end-radius: 8px;
        border-end-start-radius: 8px;
      }
    }

    &:not(.error) {
      td {
        border: 0;
        border-bottom: 1px solid $gray-light;

        .col-with-image-profile {
          .primary {
            font-size: 16px;
          }

          .secondary {
            font-size: 14px;
          }
        }

        .col-with-image {
          display: flex;
          align-items: center;

          img {
            margin-right: 0.6rem;
          }
        }

        .action-item {
          display: flex;
          align-items: center;
          gap: 8px;
          border-radius: 4px;
          padding: 12px;

          &:hover {
            background-color: $body-bg;
          }
        }
      }
    }
  }

  a:not(.btn) {
    color: var(--#{$variable-prefix}table-color);
  }
}

.group-details {
  .card-table {
    border-radius: 16px;

    .table-card-header .table-custom-header {
      margin-bottom: 0;
    }

    .table-responsive.card-body {
      padding-top: 32px !important;
    }
  }
}

.button-participant-progress {
  width: 40px !important;
  height: 40px !important;
}

@include media-breakpoint-up(xl) {
  table.advanced-table {
    th {
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px !important;
        height: 50%;
        background-color: $gray-light;
        border-radius: 8px;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .advanced-table {
    th {
      display: flex;
      align-items: center;

      .table-th {
        flex: 1;
        border: 1px solid $gray-light !important;
        justify-content: start !important;

        &::after {
          display: none !important;
        }

        &:not(.sortable) {
          display: none;
        }

        .text-center {
          text-align: left !important;
        }
      }

      .min-width-100,
      .min-width-150,
      .min-width-200 {
        min-width: unset;
      }
    }

    tbody:not(.error) {
      td {
        &::before {
          content: attr(data-label);
          font-weight: bold;
        }

        .text-center {
          text-align: left !important;
        }

        .col-with-multi-value {
          display: block;
        }
      }
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  .advanced-table {
    thead tr {
      display: block;
      margin-bottom: 1.2rem;
    }

    tbody:not(.error) {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;

      tr {
        border: 1px solid $gray-light !important;
        width: 33.3333%;
      }

      td {
        display: block;
        padding: 0.5rem 0.95rem;
        border: 0 !important;
        text-align: start !important;

        &::before {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@include media-breakpoint-between(sm, lg) {
  .advanced-table {
    thead tr {
      display: block;
      margin-bottom: 1.2rem;
    }

    tbody:not(.error) {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;

      tr {
        border-width: 0 !important;
        width: 50%;
      }

      td {
        border: 1px solid $gray-light !important;
        display: block;
        padding: 0.5rem 0.95rem;
        text-align: start !important;

        &::before {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .advanced-table {
    thead tr {
      display: block;
      margin-bottom: 1.2rem;
    }

    tbody:not(.error) {
      td {
        display: flex;
        align-items: center;
        text-align: right;
        padding: 0.4rem 0.95rem;
        border: 1px solid $gray-light !important;

        &:first-child {
          padding-top: 0.8rem;
        }

        &:last-child {
          padding-bottom: 0.55rem;
        }

        &::before {
          flex: 1;
          text-align: left;
          margin-bottom: unset;
        }

        > * {
          max-width: 100%;
        }

        .placeholder-wave {
          width: 100%;
        }

        .col-with-image {
          flex-direction: row-reverse;

          img {
            margin-right: 0;
            margin-left: 0.6rem;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .advanced-table {
    tbody:not(.error) {
      td {
        display: block;
        text-align: left;

        &::before {
          width: 100%;
          display: block;
          margin-bottom: 5px;
        }

        .col-with-image {
          flex-direction: row;

          img {
            margin-left: 0;
            margin-right: 0.6rem;
          }
        }
      }
    }
  }
}
