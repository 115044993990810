.nav-pills .nav-link {
  background-color: $gray-300;
  border-radius: 0;
  color: black;
  font-size: 1.05rem;
  padding: 15px 10px;
}

span {
  white-space: pre-wrap;
}

.border-dashed-bottom {
  border-bottom-style: dashed !important;
  border: 0.5px;
}

.border-dashed-top {
  border-top-style: dashed !important;
  border: 0.5px;
}

.col-sm-8:has(.print-width.card) {
  background-color: $gray-300;
}

.print-width.card {
  padding: 1rem;
  z-index: 1;
  max-width: 925px;
  margin: 20px auto;

  .card-body {
    z-index: 1;
  }
}

.invoice-table > thead,
.invoice-table > :not(:first-child) {
  border-top-width: 1px;
}

.invoice-table {
  border-color: $gray-400;
}

.paid-stamp {
  display: block;
  opacity: 0.2;
  position: absolute;
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-10deg);
  max-width: 50%;
}

@media print {
  .leftside-menu,
  .end-bar,
  .page-title-box,
  .navbar-custom,
  .nav-pills,
  .no-print,
  .footer {
    display: none;
  }
  .card-body,
  .card,
  .content-page,
  .end-bar,
  .content,
  body {
    font-size: 12px;
    min-height: 93vh;
    margin: 0;
    padding: 0;
  }

  .print-width.card {
    box-shadow: none;
    margin-top: 20px;
    margin-left: -12px;
    margin-right: -12px;
    padding: 0;
  }

  body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) {
    min-height: 0;
  }

  .content-page {
    display: block;
  }

  .paid-stamp {
    max-width: 100%;
  }

  .printed-row {
    .col-sm-4,
    .col-md-4,
    .col-lg-4,
    .col-xl-4,
    .col-xl-4 {
      flex: none;
      width: 33.3333%;
    }

    .col-sm-8,
    .col-md-8,
    .col-lg-8,
    .col-xl-8,
    .col-xl-8 {
      flex: none;
      width: 66.6666%;
    }

    .col-sm-7,
    .col-md-7,
    .col-lg-7,
    .col-xl-7,
    .col-xl-7 {
      flex: none;
      width: 58.3333%;
    }

    .col-sm-5,
    .col-md-5,
    .col-lg-5,
    .col-xl-5,
    .col-xl-5 {
      flex: none;
      width: 41.6667%;
    }

    .col-sm-6,
    .col-md-6,
    .col-lg-6,
    .col-xl-6,
    .col-xl-6 {
      flex: none;
      width: 50%;
    }
  }

  .border-dashed-bottom {
    border: 0.1px;
    border-bottom-style: dashed !important;
  }

  .invoice-label {
    bottom: 0;
    position: absolute;
    min-width: 100%;
    left: 0;
    right: 0;

    div,
    .row span {
      padding: 0;
    }
  }
}
