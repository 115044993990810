.legend-container {
  ul.legends-wrapper {
    display: flex;
    gap: 1rem;
    margin: 0;

    .legend-item {
      display: flex;
      gap: 0.4rem;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .color-box {
        width: 0.6rem;
        height: 0.6rem;
        display: block;
        border-radius: 2px;
      }

      .legend-label {
        font-size: 0.8rem;
        margin: 0;
      }
    }
  }
}

.chart-tooltip {
  background-color: $white !important;
  padding: 4px 6px;
  box-shadow: 0px 10px 12px -8px rgba(0, 0, 0, 0.08);
  border: 1px solid $tertiary;
  border-radius: 4px;
  position: absolute;
  pointer-events: none;
  transition: all 0.5s ease;
  transform: translate(-50%, 0);
  min-width: 75px;

  tr,
  td,
  th,
  thead {
    display: flex;
    border-width: 0;
    align-items: center;
  }

  th {
    gap: 4px;
    font-weight: 600;
    font-size: 14px;
  }

  thead {
    gap: 12px;
  }

  tbody tr {
    color: $gray-700;
    font-weight: 400;
    font-size: 12px;
  }

  .color-box {
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 2px;
  }
}
