// Button shadow

.btn {
  font-size: 0.9rem;

  &:disabled,
  &.disabled {
    color: $secondary;
    background-color: $gray-light;
    border-color: $gray-light;
  }

  &.successfully-added {
    background-color: $primary;
    opacity: 1;
    color: $white !important;
  }

  &.button-action {
    padding: 10px 16px;

    span {
      font-size: 0.9rem;
    }
  }

  &.submit-button {
    padding: 10px 16px;
    min-width: 135px;
  }

  &.button-details {
    aspect-ratio: 1/1;
    padding: 10.5px;

    &.delete-story {
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(2px);
    }
  }
}

.table-filter-toggle {
  cursor: pointer;
  flex-wrap: nowrap;
  width: fit-content;
  border: 1px solid $gray-light !important;
  border-radius: 0.5rem;

  .btn {
    outline: none !important;
    background-color: transparent;
    border: none;
    box-shadow: none !important;
  }

  &.active-filter {
    border: 1px solid $primary !important;
    outline: 1px solid $primary-light;

    .btn {
      border-right: 1px solid $primary;
    }

    &:active {
      box-shadow: 0 0 0 0.15rem $primary-light;
      transition: 0.5s;
    }
  }

  .active-count {
    color: $raisin-black;
    padding: 0 12px;
    border-radius: 0.5rem;
    border-left: 0;
    font-size: 0.9rem;
    display: grid;
    place-items: center;
  }
}

@mixin button-shadow($color) {
  box-shadow: $btn-box-shadow rgba($color, 0.5);
}

@mixin button-outline-variant($color) {
  color: $red !important;
}
