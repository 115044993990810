//
// Inter fonts
//

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Light.woff') format('woff');
  src: local('Inter Light'), local('Inter-Light'), url('../fonts/Inter-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.woff') format('woff');
  src: local('Inter Regular'), local('Inter-Regular'),
    url('../fonts/Inter-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.woff') format('woff');
  src: local('Inter Medium'), local('Inter-Medium'),
    url('../fonts/Inter-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.woff') format('woff');
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
    url('../fonts/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.woff') format('woff');
  src: local('Inter Bold'), local('Inter-Bold'), url('../fonts/Inter-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
