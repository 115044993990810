.table-filter {
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
    justify-content: center;
  }
}

.table-new {
  .btn {
    width: 100%;
    margin-bottom: 0.75rem;
  }
}

.table-card-header {
  border-bottom: none;
}

.table-custom-header {
  margin-bottom: 32px;

  .btn.group-tab {
    justify-content: center;
    flex-direction: row-reverse;
    color: $raisin-black;
    background-color: $body-bg;
    border: none;
    opacity: 1;
    width: 100%;
  }
}

.table-row-selection {
  min-height: 43.2px;
  background-color: $body-bg;
  padding: 0px 8px;
  border-radius: 8px;
  gap: 32px;
  display: flex;
  align-items: center;

  .selection-count {
    gap: 8px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    align-items: center;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .selection-action {
    display: flex;
    align-items: center;
    gap: 12px;

    .select-action {
      height: 32px;
      min-width: 175px;

      > div {
        border-radius: 4px;
      }

      .react-select__control {
        min-height: 32px;

        .react-select__value-container {
          padding: 0 0 0 12px !important;
        }

        .react-select__indicators {
          padding: 0;
          min-height: 32px;
          height: 32px;
        }
      }
    }

    button.btn {
      border: 1px solid $gray-light;
      padding: 0 4px !important;
      height: 32px;
      width: 32px;
      aspect-ratio: 1/1;

      &:hover {
        color: $white;
        background-color: $danger !important;
      }

      i {
        line-height: unset;
      }
    }
  }
}

.table-filter-section {
  position: absolute;
  right: 0;
  width: 100%;
  padding: 24px;
  z-index: 2;
  border: 1px solid $gray-light;
  border-radius: 8px;
  top: 60px;
  background-color: $white;
  box-shadow: 0px 16px 48px -12px rgba(0, 0, 0, 0.12);

  label {
    font-weight: 400;
  }
}

.table-th {
  padding: 0.75rem 0.95rem;
  position: relative;

  &.sortable {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  i {
    font-size: 18px;

    &:not(.mdi-arrow-up-down) {
      opacity: 1;
    }
  }
}

.table-pagination-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 32px;

  .table-pagination-numbers:only-child {
    margin-left: auto;
  }
}

.table-pagination-details > div {
  margin-bottom: 0.9rem;
}

.table-pagination-numbers {
  display: flex;
  align-items: center;
}

@include media-breakpoint-up(md) {
  .table-filter-section {
    right: -100%;
    width: 400px;
  }

  .table-filter {
    flex-direction: row;
    align-items: center;

    button {
      width: unset;
      justify-content: start;
      margin-top: unset;
    }
  }

  .table-new {
    display: flex;
    justify-content: flex-end;

    .btn {
      width: unset;
      margin-bottom: unset;
    }
  }

  .table-custom-header {
    .btn.group-tab {
      width: auto;
    }
  }
}

@include media-breakpoint-up(sm) {
  .table-filter-section {
    width: 400px;
  }

  .table-pagination-numbers {
    justify-content: center;
  }
}

@include media-breakpoint-up(lg) {
  .table-filter-section {
    right: -50%;
  }

  .table-pagination-details {
    > div {
      margin-bottom: 0;
    }
  }

  .table-pagination-numbers {
    justify-content: end;
  }
}
