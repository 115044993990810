.leaderboard-card {
  width: 1200;
  padding: 40px;
  aspect-ratio: 1.91 / 1;
  display: flex;
  justify-content: center;
  align-items: start;
  margin: auto;
  background-size: cover;
  overflow: hidden;
  position: relative;

  &.monthly {
    background-color: #f2bb2c;
    background-image: url('../../../assets/images/leaderboard-monthly-bg.png');
  }

  &.weekly {
    background-color: #0896de;
    background-image: url('../../../assets/images/leaderboard-bg.png');
  }

  .podium {
    margin-top: 35px;
    display: flex;
    gap: 80px;
    align-items: flex-end;

    .silver-wrapper {
      min-width: 214px;
      min-height: 214px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: -5px;
      position: relative;

      .avatar-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &.left {
          top: 0;
          left: 0;
          position: absolute;
        }

        &.right {
          bottom: 0;
          right: 0;
          position: absolute;
        }
      }

      .avatar {
        background-color: #005959;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        outline: 10px solid #ffffff;
        z-index: 1;

        &.small {
          width: 105px;
          height: 105px;
        }

        &.big {
          width: 214px;
          height: 214px;
        }

        .avatar-text {
          &.small {
            font-size: 42px;
            line-height: 42px * 1.2;
            letter-spacing: -1.08px;
          }

          &.big {
            font-size: 82px;
            line-height: 82px * 1.2;
            letter-spacing: -1.62px;
          }
        }
      }

      .medal {
        margin-top: -5px;
        z-index: 2;
        border-radius: 50%;
        outline: 6.6px solid #ffffff;
        position: relative;
        background-color: #cddcda;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #466963;

        &.small {
          width: 35px;
          height: 35px;
        }

        &.big {
          width: 70px;
          height: 70px;
        }

        .medal-text {
          font-weight: 600;
          text-align: center;

          &.small {
            font-size: 24px;
            line-height: 24px * 1.2;
            letter-spacing: -0.45px;
          }

          &.big {
            font-size: 34.2px;
            line-height: 47.02px;
            letter-spacing: -0.8549618124961853px;
          }
        }
      }
    }

    .gold-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 305px;
      height: 305px;

      .avatar-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &.left {
          top: 0;
          left: 0;
          position: absolute;
        }

        &.right {
          bottom: 0;
          right: 0;
          position: absolute;
        }
      }

      .avatar {
        background-color: #005959;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        outline: 10px solid #ffffff;
        z-index: 1;

        &.small {
          width: 150px;
          height: 150px;
        }

        &.big {
          width: 305px;
          height: 305px;
        }

        .avatar-text {
          &.small {
            font-size: 68px;
            line-height: 68px * 1.2;
            letter-spacing: -1.08px;
          }

          &.big {
            font-size: 108px;
            line-height: 108px * 1.2;
            letter-spacing: -1.62px;
          }
        }
      }

      .medal {
        margin-top: -12px;
        z-index: 2;
        border-radius: 50%;
        outline: 6.6px solid #ffffff;
        position: relative;
        background-color: #f3d97f;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #7a6109;

        &.small {
          width: 50px;
          height: 50px;
        }

        &.big {
          width: 85px;
          height: 85px;
        }

        .medal-text {
          font-weight: 600;
          text-align: center;

          &.small {
            font-size: 32px;
            line-height: 32px * 1.2;
            letter-spacing: -0.45px;
          }

          &.big {
            font-size: 37.62px;
            line-height: 51.73px;
            letter-spacing: -0.9404579997062683px;
          }
        }
      }
    }

    .bronze-wrapper {
      min-width: 214px;
      min-height: 214px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: -5px;
      position: relative;

      .avatar-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &.left {
          top: 0;
          left: 0;
          position: absolute;
        }

        &.right {
          bottom: 0;
          right: 0;
          position: absolute;
        }
      }

      .avatar {
        background-color: #005959;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 214px;
        height: 214px;
        border-radius: 50%;
        outline: 10px solid #ffffff;
        z-index: 1;

        &.small {
          width: 105px;
          height: 105px;
        }

        &.big {
          width: 214px;
          height: 214px;
        }

        .avatar-text {
          &.small {
            font-size: 42px;
            line-height: 42px * 1.2;
            letter-spacing: -1.08px;
          }

          &.big {
            font-size: 82px;
            line-height: 82px * 1.2;
            letter-spacing: -1.62px;
          }
        }
      }

      .medal {
        margin-top: -5px;
        z-index: 2;
        border-radius: 50%;
        outline: 6.6px solid #ffffff;
        position: relative;
        background-color: #f1c69e;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #8a521e;

        &.small {
          width: 35px;
          height: 35px;
        }

        &.big {
          width: 70px;
          height: 70px;
        }

        .medal-text {
          font-weight: 600;
          text-align: center;

          &.small {
            font-size: 24px;
            line-height: 24px * 1.2;
            letter-spacing: -0.45px;
          }

          &.big {
            font-size: 34.2px;
            line-height: 47.02px;
            letter-spacing: -0.8549618124961853px;
          }
        }
      }
    }
  }

  .title-wrapper {
    height: 100px;
    width: 85%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;

    .logo-image {
      width: 36px;
      background-image: url('../../../assets/images/logo-color-sm.svg');
      aspect-ratio: 9 / 8;
      background-position: center;
      background-size: cover;
    }

    .event-details-text {
      max-width: 70%;
      word-break: keep-all;
      font-size: 36px;
      font-weight: 600;
      line-height: 50px;
      letter-spacing: -0.8px;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #222222;
    }
  }
}

.default-avatar-text {
  font-size: 4rem;
}
