//
// _badges.scss
//

// Lighten badge (soft)

$badge-list-colors: (
  'primary-light' $primary-light $green-dark,
  'danger-light' $danger-light $danger-dark,
  'secondary' $gray-light $secondary,
  'primary' $primary $raisin-black,
  'primary-dark' $primary $green-dark,
  'yellow' $yellow-light $yellow-dark,
  'gold-dark' $yellow $gold-dark
);

@each $color, $value in $theme-colors {
  .badge-#{$color}-lighten {
    @include badge-variant-light($value);
    &[href] {
      color: $value;
      text-decoration: none;
      background-color: rgba($value, 0.18);
    }
    &[href] {
      &:hover,
      &:focus {
        color: $value;
        text-decoration: none;
        background-color: rgba($value, 0.4);
      }
    }
  }
}

@each $name, $bg, $color in $badge-list-colors {
  .badge {
    display: flex;
    align-items: center;
    margin: 0;
    width: fit-content;
    line-height: normal;
    padding: 0.25em 0.5em;
    font-size: 12px;
    height: fit-content !important;
    font-weight: 500;

    .avatar-icon {
      padding: 0.25rem;
    }

    i {
      margin-top: 2px;
    }

    &.custom-badge {
      gap: 4px !important;
      font-size: 13px;

      i ~ span {
        margin-right: 5px !important;
      }

      &.badge-custom-outline-#{$name} {
        color: $color !important;
        background-color: white !important;
        border-color: $bg !important;
        padding: 2px;

        .icon {
          color: $color !important;
          margin-top: 0;
        }
      }
    }

    &.bg-#{$name} {
      color: $color;
      background-color: $bg !important;
      border-color: $bg;

      .badge-select-remove {
        border-radius: 0 0.25rem 0.25rem 0;
        padding: 0 0.3rem;
        margin: -0.25rem -0.4rem -0.25rem 0.25rem;
        height: 25px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: darken($bg, 10%) !important;
        }
      }

      .icon {
        color: $color;
        margin-top: 1px;
      }

      strong {
        font-size: 1rem !important;
      }
    }
  }
}

// Outline badge

@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-variant-outline($value);
    &[href] {
      color: $value;
      text-decoration: none;
      background-color: rgba($value, 0.2);
    }
    &[href] {
      &:hover,
      &:focus {
        color: $value;
        text-decoration: none;
        background-color: rgba($value, 0.2);
      }
    }
  }
}

.badge {
  vertical-align: middle;
  &.badge-lg {
    padding: $badge-padding-y * 2 $badge-padding-x * 2.5;
    font-size: $badge-font-size + 0.1em;
  }
}

// Lighten badge (soft)

// Dark mode
body[data-layout-color='dark'] {
  // Lighten badge (soft)
  @each $color, $value in $dark-theme-colors {
    .badge-#{$color}-lighten {
      @include badge-variant-light($value);
      &[href] {
        color: $value;
        text-decoration: none;
        background-color: rgba($value, 0.18);
      }
      &[href] {
        &:hover,
        &:focus {
          color: $value;
          text-decoration: none;
          background-color: rgba($value, 0.4);
        }
      }
    }
  }

  // Outline badge
  @each $color, $value in $dark-theme-colors {
    .badge-outline-#{$color} {
      @include badge-variant-outline($value);
      &[href] {
        color: $value;
        text-decoration: none;
        background-color: rgba($value, 0.2);
      }
      &[href] {
        &:hover,
        &:focus {
          color: $value;
          text-decoration: none;
          background-color: rgba($value, 0.2);
        }
      }
    }
  }
}

.badge-app-version {
  padding: 0.2rem;
  display: flex;
}

.badge-group-status {
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
