//
// modal.scss
//

// Title text within header
.modal-title {
  margin-top: 0;
}

// Modal full width
.modal-full-width {
  width: 95%;
  max-width: none;
}

// Modal Positions
.modal-top {
  margin: 0 auto;
}

// Right Modal
.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: $modal-content-bg;
  align-content: center;
  transform: translate(25%, 0) !important;

  button.btn-close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1;
  }
}

.modal {
  &.show {
    .modal-right,
    .modal-left {
      transform: translate(0, 0) !important;
    }
  }

  .modal-dialog,
  .modal-body,
  .modal-content {
    border-radius: 16px;
  }

  &.confirmation-modal {
    .modal-dialog,
    .modal-body,
    .modal-content {
      max-width: 450px;
    }
  }

  .modal-body-content,
  .modal-body {
    padding: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      font-size: 0.9rem;
    }

    .button-close-modal {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .image-frame {
    width: 160px;
    height: 160px;
    margin-bottom: 32px;
    background-color: $gray-light;
    position: relative;

    img {
      width: 100%;
      aspect-ratio: 1/1;
    }

    .delete-icon {
      position: absolute;
      top: 16%;
      left: 49%;
      color: $white;
      transform: translate(-51%, 0);
    }
  }

  .content-title {
    text-align: center;
  }
}

// Bottom modal
.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center;
}

// Colored modal header
.modal-colored-header {
  color: $white;
  border-radius: 0;

  .btn-close {
    color: $white !important;
  }
}

// Full filled modals
.modal-filled {
  color: $white;

  .modal-header {
    background-color: rgba($white, 0.07);
  }

  .modal-header,
  .modal-footer {
    border: none;
  }

  .btn-close {
    color: $white !important;
  }
}

.modal-story-details {
  .modal-body {
    padding: 24px;

    .story-details-slider {
      height: 100%;
      border-radius: 10px;
      position: relative;
      padding-bottom: calc(16 / 9 * 100%);
      width: 100%;

      .rounded-image {
        margin-right: 0.2rem;
        margin-top: -0.2rem;
        padding: 0.2rem;
      }

      .btn {
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(2px);
      }

      .story-card-swiper::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 0px 200px 100px -125px rgba(0, 0, 0, 0.88) inset;
        -webkit-box-shadow: 0px 200px 100px -125px rgba(0, 0, 0, 0.88) inset;
        -moz-box-shadow: 0px 200px 100px -125px rgba(0, 0, 0, 0.88) inset;
        width: 100%;
        border-radius: 10px;
        height: 100%;
        z-index: 1;
      }

      .story-card-swiper {
        border-radius: 10px;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        pointer-events: none !important;
      }
    }

    ul.list-unstyled {
      height: 100%;

      .list-story-details {
        height: calc(100% / 8);
        padding: 0rem 1.5rem;
        border: 1px solid $tertiary;
        text-align: start;
        border-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;

        &:last-child {
          border-bottom: 1px solid $tertiary;
          border-radius: 0 0 0.5rem 0.5rem;
        }

        &:first-child {
          border-radius: 0.5rem 0.5rem 0 0;
          gap: 0;
        }

        &:nth-child(2) {
          gap: 0;
        }
      }
    }
  }
}
