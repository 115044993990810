//
// timeline.scss
//

.timeline {
  position: relative;
}

.timeline-item {
  position: relative;

  &:before {
    content: '';
    left: 1rem;
    position: absolute;
    border-left: 4px dotted $gray-light;
    width: 4px;
    z-index: 0;
    top: calc(50%);
    height: 80%;
    transform: translateY(calc(-50% + 0.5rem));
  }

  &:last-child::before {
    content: none;
  }

  .dot-timeline {
    height: 0.25rem;
    width: 0.25rem;
    border-radius: 50%;
    display: inline-block;
    background-color: $gray-light;
  }

  .last-timeline {
    width: 0.125rem;
    height: 80%;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(calc(-50% + 0.75rem));

    &::before {
      content: '';
      top: 0;
      left: -50%;
      position: absolute;
      width: 12px;
      z-index: 0;
      height: 4px;
      transform: translateX(calc(-50% + 0.15rem));
      background-color: $gray-light;
      border-radius: 1.2rem;
    }

    &::after {
      content: '';
      top: 8px;
      left: -50%;
      position: absolute;
      width: 4px;
      z-index: 0;
      height: 4px;
      transform: translateX(calc(-50% + 0.15rem));
      background-color: $gray-light;
      border-radius: 50%;
    }
  }
}

.timeline-icon {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 1.25rem;
  width: 1.25rem;
  justify-content: center;
  left: 0.5rem;
  position: absolute;
  top: -0.15rem;
  border: 3px solid $white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
}

.timeline-item,
.timeline-wrapper {
  &:first-child {
    .timeline-icon {
      background-color: $primary;
    }
  }

  &:nth-child(2) {
    .timeline-icon {
      background-color: $yellow;
    }
  }

  &:last-child {
    .timeline-icon {
      background-color: $red !important;
    }
  }
}

.timeline-wrapper {
  width: calc(32% + 1px);
}

// Dark mode
body[data-layout-color='dark'] {
  .timeline {
    &:before {
      background-color: #{map-get($dark-grays, '300')};
    }
  }

  @include media-breakpoint-up(md) {
    .timeline {
      .timeline-icon {
        background: #{map-get($dark-grays, '300')};

        i {
          color: #{map-get($dark-grays, '600')};
        }
      }
    }
  }
}
