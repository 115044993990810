.img-slider-thumbnail {
  height: 75px;
  width: 100px;
  object-fit: contain;
}

.img-slider {
  width: 100%;
  height: 340px;
  object-fit: contain;
}

@include media-breakpoint-down(sm) {
  .img-slider {
    height: 200px;
  }
}
