// Utilities

@import 'utilities/background';
@import 'utilities/text';

// border style
.border-dashed {
  border-style: dashed !important;
}

.border-dashed-bottom {
  border-bottom-style: dashed !important;
}

.mt-min-1 {
  margin-top: -0.375rem !important;
}

// Dark mode
body[data-layout-color='dark'] {
  @each $color, $value in $dark-theme-colors {
    .border-#{$color} {
      border-color: $value !important;
    }
  }
}

.max-width-100 {
  max-width: 100% !important;
}

$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
);

.gap-2px {
  gap: 2px;
}

$utilities: map-merge(
  $utilities,
  (
    'object-fit': (
      responsive: true,
      property: object-fit,
      values: (
        contain: contain,
        cover: cover,
        fill: fill,
        scale: scale-down,
        none: none,
      ),
    ),
    'flex': (
      property: flex,
      class: flex,
      values: (
        1: 1 1 0%,
        auto: 1 1 auto,
        none: none,
      ),
    ),
    'z-index': (
      property: z-index,
      class: z,
      values: $zindex-levels,
    ),
    'white-space': (
      property: white-space,
      class: text,
      values: (
        wrap: normal,
        nowrap: nowrap,
      ),
    ),
  )
);

@import 'node_modules/bootstrap/scss/utilities/api';
