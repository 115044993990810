//
// text.scss
//

// Weight

.fw-semibold {
  font-weight: $font-weight-semibold !important;
}

.fw-medium {
  font-weight: $font-weight-medium !important;
}

.text-body {
  color: #{map-get($grays, '700')} !important;
}

.text-pre-line {
  white-space: pre-line;
}

.text-balance {
  text-wrap: balance;
}

// Dark mode
body[data-layout-color='dark'] {
  @each $color, $value in $dark-theme-colors {
    .text-#{$color} {
      color: $value !important;
    }
  }
  .text-body {
    color: #{map-get($dark-grays, '500')} !important;
  }
}

.body-2 {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.4px;
}
